import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import naive from "naive-ui";
import Vue3Marquee from "vue3-marquee";
import "primevue/resources/themes/aura-light-green/theme.css";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Paginator from "primevue/paginator";
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import VirtualScroller from "primevue/virtualscroller";
import "ant-design-vue/dist/reset.css";
import Antd from "ant-design-vue";
import Carousel from "primevue/carousel";
import Image from "primevue/image";
import Card from "primevue/card";
import ProgressSpinner from "primevue/progressspinner";
import { createPinia } from "pinia";
const app = createApp(App);
const pina = createPinia();
app.use(pina);
app.use(store);
app.use(router);
app.use(naive);
app.use(PrimeVue);
app.use(Antd);
app.component("VirtualScroller", VirtualScroller);
app.component("TabView", TabView);
app.component("CarouselView", Carousel);
app.component("DialogView", Dialog);
app.component("TabPanel", TabPanel);
app.component("ImageView", Image);
app.component("CardView", Card);
app.component("PaginatorComponent", Paginator);
app.component("ProgressSpinner", ProgressSpinner);
app.use(Vue3Marquee);
app.mount("#app");
