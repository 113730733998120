<template>
  <div class="row justify-content-center">
    <div class="col-lg-8 video-box">
      <div class="row no-gutters justify-content-center">
        <img
          v-if="showImage"
          id="imgFrame"
          src="assets/img/video-youtude-interview-president-tidjane-thiam.png"
          class="img-fluid"
          alt="image_PDCI"
        />
        <button
          id="imgButtonFrame"
          @click="playVid"
          class="venobox play-btn mb-4"
          data-vbtype="video"
          data-autoplay="true"
        ></button>
        <iframe
          id="vidFrame"
          width="709"
          height="399"
          :src="myLien"
          title="Tidjane Thiam dévoile son bureau et met en mission les militants du PDCI-RDA"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VideoPresident",
  props: ["isImage", "lien"],
  computed: {
    myLien() {
      return this.lien;
    },
    showImage() {
      return this.isImage;
    },
  },
  methods: {
    playVid() {
      const img = document.getElementById("imgFrame");
      const vid = document.getElementById("vidFrame");
      const but = document.getElementById("imgButtonFrame");
      img.style.display = "none";
      but.style.display = "none";
      let symbol = vid.src.indexOf("?") > -1 ? "&" : "?";
      vid.src += symbol + "autoplay=1";
      vid.style.display = "inline";
    },
  },
};
</script>
