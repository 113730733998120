<script>
import { ref, onMounted, watch } from "vue";
import instance from "@/api";
import { useStore } from "vuex";
import { Help } from "@/views/Utils";
// import Editor from "@/components/TextEditor.vue";
export default {
  name: "InterfaceChatBot",
  props: ["idChatBot"],
  // components: {
  //   Editor,
  // },
  setup(props) {
    const store = useStore();
    const idDiscussion = ref(props.idChatBot);
    const essaiTableau = ref([]);
    const handleBtnDiscussion = () => {
      store.commit("TOGGLE_DISCUSSION", false);
    };
    const erroMsg = ref(false);
    const handleListMessage = ref(null);
    const pollingInterval = ref("");
    const textChatBot = ref("");
    const myNewDate = ref("");
    const titreOfConversation = ref("");
    const isLoadingDiscussion = ref(false);
    const count_messages = ref(0);
    const listMessageByDate = ref(null);
    const nameOfAdmin = ref("");
    const textTemporaire = ref("");
    const replaceNewlinesWithBr = (texte) => {
      return texte.replace(/\n/g, "<br>");
    };
    const sendWithChatBot = async () => {
      console.log("ID", document.getElementById("texte_area"));
      console.log("chatBot", textChatBot.value);
      const formattedText = replaceNewlinesWithBr(textChatBot.value);
      isLoadingDiscussion.value = true;
      if (!textChatBot.value) {
        erroMsg.value = true;
        isLoadingDiscussion.value = false;
      } else {
        const idChat = props.idChatBot;
        let data = {
          texte: formattedText,
          chat_id: idChat,
        };
        textTemporaire.value = formattedText;
        console.log("DATA", data);
        myNewDate.value = new Date().toLocaleDateString("fr");
        essaiTableau.value.push(formattedText);
        for (let item in listMessageByDate.value) {
          console.log("ITEM", typeof item);
          if (item === new Date().toLocaleDateString("fr").toString()) {
            listMessageByDate.value[item].push({
              id: new Date(),
              sender_id: store.state.user.id,
              texte: formattedText,
              created_at: new Date(),
            });
            console.log("ADD");
          }

          // if (item !== new Date().toLocaleDateString("fr").toString()) {
          //   listMessageByDate.value[myNewDate.value] = [
          //     {
          //       id: new Date(),
          //       sender_id: store.state.user.id,
          //       texte: formattedText,
          //       created_at: new Date(),
          //     },
          //   ];
          //   console.log("CREATE");
          // }
        }

        console.log("NEW listMessageByDate.value", listMessageByDate.value);
        try {
          const reponse = await instance.post("messages", data);
          scrollToBottom();
          console.log("REPONSE-MESSAGE3", reponse.data);
          textChatBot.value = "";
          console.log("essaiTableau.value", essaiTableau.value);
          isLoadingDiscussion.value = false;
        } catch (error) {
          console.log(error);
          isLoadingDiscussion.value = false;
        }
      }
    };
    const handleTexte = (e) => {
      console.log("handleTexte", e);
    };
    const getListMessage = async () => {
      if (idDiscussion.value) {
        try {
          const reponse = await instance.get("chats/" + idDiscussion.value);
          console.log("REPONSE", reponse.data.data.messages);
          count_messages.value = reponse.data.data.messages.length;
          console.log("count", count_messages.value);
          console.log(
            "getListMessage2",
            Help.groupBy(reponse.data.data.messages)
          );
          listMessageByDate.value = Help.groupBy(reponse.data.data.messages);
          titreOfConversation.value = reponse.data.data.titre;
          nameOfAdmin.value = reponse.data.data.admin
            ? reponse.data.data.admin.nom
            : "";
          handleListMessage.value = reponse.data.data.messages;
          textTemporaire.value = "";
        } catch (error) {
          console.log(error);
        }
      } else {
        return;
      }
    };
    const insertLineBreak = (event) => {
      // Ajoute un retour à la ligne à la position actuelle du curseur
      // textChatBot.value += "\n";
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      textChatBot.value =
        textChatBot.value.substring(0, start) +
        "\r\n" +
        textChatBot.value.substring(end);
    };

    function scrollToBottom() {
      const container = document.getElementById("chatContainerOldDiscussion");
      console.log("container", container);
      if (container != null) {
        container.scrollTop = container.scrollHeight;
      }
    }

    const startMessagePolling = async () => {
      pollingInterval.value = setInterval(getListMessage, 10000);
    };
    console.log("allDiscussion", props.tableauDiscussion);
    onMounted(async () => {
      try {
        getListMessage();
        startMessagePolling();
        watch(count_messages, (newValue, oldvalue) => {
          if (newValue != oldvalue) {
            scrollToBottom();
          }
        });
      } catch (error) {
        console.log(error);
      }
    });
    return {
      handleBtnDiscussion,
      sendWithChatBot,
      titreOfConversation,
      handleListMessage,
      listMessageByDate,
      textChatBot,
      isLoadingDiscussion,
      erroMsg,
      nameOfAdmin,
      textTemporaire,
      insertLineBreak,
      store,
      handleTexte,
    };
  },
};
</script>
<template>
  <div class="conteneur-discussion position-absolute">
    <div class="header d-flex justify-content-between px-4 py-2">
      <div style="text-align: left" class="d-flex align-items-center">
        <h3 class="fw-bold">
          <i class="bi bi-arrow-left" @click="handleBtnDiscussion"></i>
        </h3>
        <i class="bi bi-person-circle h3 mx-2"></i>
        {{ nameOfAdmin }}
      </div>
      <h3 @click="handleBtnDiscussion" style="cursor: pointer">x</h3>
    </div>
    <div class="main" id="chatContainerOldDiscussion" v-if="handleListMessage">
      <div v-for="(value, index) in listMessageByDate" :key="index">
        <span class="badge bg-secondary">{{ index }}</span>
        <div
          class="d-flex reponse-question"
          v-for="item in value"
          :key="item.id"
        >
          <p
            class="d-flex align-self-end position-relative"
            v-if="item.sender_id == $store.state.user.id"
          >
            <span class="questions text-end mx-1" v-html="item.texte"></span>
            <span class="position-absolute bottom-0 end-0">{{
              new Intl.DateTimeFormat("fr", {
                timeStyle: "short",
              }).format(new Date(item.created_at))
            }}</span>
          </p>
          <p
            class="d-flex align-self-start position-relative"
            v-if="item.sender_id != $store.state.user.id"
          >
            <span class="response mx-1">{{ item.texte }}</span>
            <span class="position-absolute bottom-0 white">{{
              new Intl.DateTimeFormat("fr", {
                timeStyle: "short",
              }).format(new Date(item.created_at))
            }}</span>
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      {{ $store.state.isLanguage === "FR" ? "Chargement..." : "Loading..." }}
    </div>
    <div class="footer">
      <p v-if="erroMsg" class="text-danger">
        {{
          $store.state.isLanguage === "FR"
            ? "Veuillez-remplir le champ"
            : "Please fill in the field"
        }}
      </p>
      <form>
        <div class="d-flex align-content-center gap-3">
          <!-- <editor v-model="textChatBot" /> -->
          <textarea
            v-model="textChatBot"
            @keyup.enter.exact="sendWithChatBot"
            name=""
            @change="handleTexte"
            :placeholder="
              $store.state.isLanguage === 'FR'
                ? 'Saisissez votre message ici'
                : 'Enter your message here'
            "
            id="texte_area"
            cols="20"
          ></textarea>
          <button
            class="btn-lg button"
            @click.prevent="sendWithChatBot"
            v-if="!isLoadingDiscussion"
          >
            <i class="bi bi-send-fill"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
span p {
  margin: 0 !important;
  padding: 0;
}
.button {
  position: absolute;
  right: -1em;
  top: 0.5em;
}
.white {
  right: 0;
}
.bottom-0 {
  font-size: 0.8em !important;
  font-weight: bold;
  bottom: -1.3em !important;
}
textarea {
  width: 88%;
}
button {
  background: transparent;
  border: none;
}
.conteneur-discussion {
  background: rgb(249, 249, 249);
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}
.btn-conversation {
  background: #128743;
  color: white;
  border: none;
  padding: 0.5em;
}
.questions {
  background: #128743;
  color: white;
  width: auto;
  padding: 0.5em;
  border-radius: 10px 10px 5px 10px;
}
.reponse-question {
  flex-direction: column;
  gap: 1em;
}
.two-flex {
  flex: 2;
  padding: 0 1em;
}
.conteneur-discussion {
  border-bottom: thin solid gray;
}
.bi-send-fill {
  position: absolute;
  right: 1em;
  top: 35%;
  font-size: 1.5em;
  color: green;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.conteneur-conversations {
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  z-index: 30;
}
.bi-arrow-left-circle,
.bi-person-circle,
.bi-arrow-right {
  font-size: 1.5em;
  cursor: pointer;
}
.bi-arrow-right {
  color: orange;
}
.conteneur {
  width: 400px;
  height: 490px;
  position: relative;
  background: wheat;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.448);
  border-radius: 10px 10px 0 0;
}
.header {
  background: white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.248);
  border-radius: 10px 10px 0 0;
}
.main {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  gap: 1em;
  background: #f5deb3;
  padding: 0.8em 0.8em 2em 0.8em;
  height: 375px;
}
.main_large {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  gap: 1em;
  background: #f5deb3;
  padding: 0.8em 0.8em 2em 0.8em;
  height: 600px;
}
.response {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: auto;
  padding: 1em;
  border-radius: 10px 5px 10px 10px;
}
h6 {
  padding: 0.5em;
  background: white;
  border-radius: 10px;
  border: 2px solid green;
  color: rgb(90, 112, 90);
  cursor: pointer;
}
.badge {
  position: sticky !important;
  top: 1px;
  z-index: 60;
}
</style>
