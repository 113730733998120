<script>
import instance from "@/api";
import InterfaceChatBot from "@/components/feature/InterfaceChatBot.vue";
import { Help } from "@/views/Utils";
import ConversationChatBot from "@/components/feature/ConversationChatBot.vue";
import Swal from "sweetalert2";
export default {
  name: "ChatBot",
  props: {
    isOpen: Boolean,
  },
  components: {
    InterfaceChatBot,
    ConversationChatBot,
  },
  data() {
    return {
      texte: "",
      Help: Help,
      isLoading: false,
      question: "",
      textChatBot: "",
      erroMsg: false,
      isAssistance: false,
      showConversation: false,
      showADiscussion: false,
      idChat: "",
      listMessage: [],
      pollingInterval: "",
      isOnlineConversation: false,
      idOldDiscuccion: "",
      islarge: false,
    };
  },
  computed: {
    toogleChatBot() {
      return this.isOpen;
    },
  },
  methods: {
    handleIsLarge() {
      this.$store.commit("TOGGLE_ISITLARGE");
      this.islarge = !this.islarge;
    },
    async getListMessage() {
      try {
        const reponse = await instance.get("chats");
        console.log("getListMessage", reponse.data);
        this.listMessage = reponse.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async sendTitleForChatBot() {
      try {
        const reponse = await instance.post("chats");
        this.idChat = reponse.data.data.id;
        this.$store.commit("STOCK_ID", this.idChat);
        console.log("this.idChat", this.idChat);
        sessionStorage.setItem("ID_chat", this.idChat);
        // this.getListMessage();
      } catch (error) {
        console.log(error);
      }
    },

    handleBtnDiscussion(e) {
      console.log("EVENT", e);
      this.$store.commit("TOGGLE_DISCUSSION", true);
      this.idOldDiscuccion = e;
      // this.$emit("change", true);
    },
    closeChatBot() {
      this.$emit("update", false);
    },
    scrollToBottom() {
      const container = document.getElementById("chatContainer");
      container.scrollTop = container.scrollHeight;
      console.log("D2CLENCHEr");
    },
    showMenu(el) {
      console.log(el);
      this.isLoading = true;
      this.question = el.module;
      if (this.question === "Assistance" && !this.$store.state.user) {
        Swal.fire({
          icon: "info",
          title: "Veuillez-vous connecter ?",
          showConfirmButton: true,
        });
        this.closeChatBot();
        this.$router.push("/login");
      } else if (this.question === "Forum") {
        setTimeout(() => {
          this.isLoading = false;
          this.$store.commit("CHANGE_CHAT_BOT", el);
        }, 100);
        this.scrollToBottom();
      } else {
        this.isAssistance = true;
        this.isOnlineConversation = true;
        this.sendTitleForChatBot();
        setTimeout(() => {
          this.isLoading = false;
          this.$store.commit("CHANGE_CHAT_BOT", el);
        }, 100);
        this.scrollToBottom();
      }
    },
    async sendWithChatBot(textChatBot) {
      console.log("chatBot", textChatBot);
      const idChat = sessionStorage.getItem("ID_chat");
      let data = {
        texte: textChatBot,
        chat_id: idChat,
      };
      console.log("DATA", data);
      try {
        const reponse = await instance.post("messages", data);
        console.log("REPONSE-MESSAGE1", reponse.data);
        this.getListMessage();
      } catch (error) {
        console.log(error);
      }
      if (!this.textChatBot) {
        this.erroMsg = true;
      } else {
        this.$store.commit("ADD_QUESTION_CONVERSATION", this.textChatBot);
      }
    },
    searchHandle() {
      console.log(this.texte);
      this.$store.state.menuChatBot.forEach((item) => {
        if (this.texte === item.module) {
          this.showMenu(item);
        }
      });
    },
    closeDiscussionOnline(e) {
      this.isOnlineConversation = e;
      console.log("closeSession");
      sessionStorage.removeItem("ID_chat");
      this.isAssistance = !this.isAssistance;
      this.isLoading = !this.isLoading;
      this.$store.commit("VIDE_CHAT_BOT");
    },
    toggleConversations() {
      this.getListMessage();
      this.showConversation = true;
    },
  },
  created() {
    this.getListMessage();
    this.$store.commit("VIDE_CHAT_BOT");
  },
};
</script>

<template>
  <div
    style="
      display: flex;
      justify-content: flex-end;
      position: fixed;
      bottom: 0;
      z-index: 999;
      width: 100%;
      right: 0;
    "
    v-show="isOpen"
  >
    <div :class="!islarge ? 'conteneur' : 'conteneur_large'">
      <div
        class="position-absolute conteneur-discussion_all"
        v-if="this.$store.state.showADiscussion"
      >
        <InterfaceChatBot :idChatBot="idOldDiscuccion" />
      </div>
      <div
        class="conteneur-conversations position-absolute"
        v-if="showConversation"
      >
        <div class="header d-flex justify-content-between px-4 py-2">
          <div style="text-align: left">
            <i
              class="bi bi-arrow-left-circle"
              @click="showConversation = false"
            ></i>
          </div>
          <div>
            <h5>
              {{
                this.$store.state.isLanguage === "FR"
                  ? "Mes conversations"
                  : "Conversations"
              }}
            </h5>
          </div>
        </div>
        <div class="main_mes_conversation">
          <div
            v-for="item in listMessage"
            :key="item.id"
            class="d-flex justify-content-between align-items-center conteneur-discussion"
            @click.prevent="handleBtnDiscussion(item.id)"
          >
            <span class="one-flex"><i class="bi bi-person-circle"></i></span>
            <div class="text-start two-flex">
              <div class="d-flex align-items-center gap-3 conteneur-ecriteau">
                <h5 class="fw-bold p-1 m-0">
                  {{ item.titre }}
                </h5>
                <span class="mx-2">{{
                  item.admin ? item.admin.nom : "ChatBot"
                }}</span>
              </div>
              <span
                class="text-secondary d-block"
                :class="
                  item.messages.length && item.messages[0].texte.length > 15
                    ? 'other_texte-coupe_conversation'
                    : null
                "
                v-html="item.messages.at(-1).texte"
                v-if="item.messages.length ? item.messages.at(-1).texte : null"
              ></span>
              <span class="text-secondary">
                {{
                  item.messages.length
                    ? Help.diffForHumans(item.messages.at(-1).updated_at)
                    : null
                }}
              </span>
            </div>
            <span
              class="three-flex"
              @click.prevent="handleBtnDiscussion(item.id)"
            >
              <i class="bi bi-arrow-right"></i>
            </span>
          </div>
        </div>
      </div>
      <div
        class="position-absolute conteneur-discussion_start"
        v-if="isOnlineConversation"
      >
        <ConversationChatBot
          :isAssistance="isAssistance"
          :question="question"
          :isLoading="isLoading"
          @close="closeDiscussionOnline"
        />
      </div>

      <div class="header d-flex justify-content-between px-4 py-2">
        <div style="text-align: left">
          <h3 class="fw-bold">ChatBot</h3>
        </div>
        <div class="d-flex align-items-center g-3">
          <button
            v-if="this.$store.state.user"
            class="btn-conversation mx-2"
            @click="toggleConversations"
          >
            Conversations <i class="bi bi-chat-left-text"></i>
          </button>
          <!-- <i
            v-if="!islarge"
            class="bi bi-arrows-angle-expand mx-2"
            @click="handleIsLarge"
          ></i>
          <i
            v-if="islarge"
            class="bi bi-arrows-angle-contract mx-2"
            @click="handleIsLarge"
          ></i> -->
          <h3 @click="closeChatBot" class="mx-3" style="cursor: pointer">x</h3>
        </div>
      </div>
      <div class="main" id="chatContainer">
        <div
          class="d-flex reponse-question"
          v-for="item in this.$store.state.reponseChatBot"
          :key="item.module"
        >
          <p class="d-flex align-self-end">
            <span class="questions text-end mx-1">{{ item.module }}</span>
          </p>
          <p class="d-flex align-self-start">
            <span class="response mx-1">{{ item.reponse }}</span>
          </p>
        </div>
        <div class="d-flex reponse-question" v-if="isLoading && isAssistance">
          <p class="d-flex align-self-end">
            <span class="questions text-end mx-1">{{ this.question }}</span>
          </p>
          <p class="d-flex align-self-start">
            <span class="response mx-1">Chargement...</span>
          </p>
        </div>
        <div></div>

        <div
          class="response mx-1 my-3"
          v-if="!this.$store.state.reponseChatBot.length"
        >
          {{
            this.$store.state.isLanguage === "FR"
              ? "Bonjour que puis-je faire pour vous ?"
              : "Hello, what can I do for you?"
          }}
        </div>
        <div
          class="d-flex flex-column align-items-center flex-wrap"
          v-if="!isAssistance"
        >
          <h6
            @click="showMenu(item)"
            v-for="item in this.$store.state.menuChatBot"
            :key="item.module"
          >
            {{ item.module }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
span p {
  margin: 0 !important;
  padding: 0;
}
.three-flex {
  position: absolute;
  right: 1em;
}
.btn-conversation {
  background: #128743;
  color: white;
  border: none;
  padding: 0.5em;
}
.questions {
  background: #128743;
  color: white;
  width: auto;
  padding: 1em;
  border-radius: 10px 10px 5px 10px;
}
.reponse-question {
  flex-direction: column;
  gap: 1em;
}
.two-flex {
  flex: 2;
  padding: 0 1em;
}
.conteneur-discussion {
  border-bottom: thin solid gray;
  cursor: pointer;
  position: relative;
}
.bi-send-fill {
  position: absolute;
  right: 1em;
  top: 35%;
  font-size: 1.5em;
  color: green;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.conteneur-conversations {
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  z-index: 30;
  border-radius: 10px 10px 0 0;
}
.conteneur-discussion_all {
  z-index: 60;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.conteneur-discussion_start {
  z-index: 50;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.bi-arrow-left-circle,
.bi-person-circle,
.bi-arrow-right {
  font-size: 1.5em;
  cursor: pointer;
}
.bi-arrow-right {
  color: orange;
}
.conteneur {
  width: 400px;
  height: 490px;
  position: relative;
  background: wheat;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.448);
  border-radius: 10px 10px 0 0;
}
.conteneur_large {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: wheat;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.448);
  border-radius: 10px 10px 0 0;
}
.header {
  background: white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.337);
  border-radius: 10px 10px 0 0;
  z-index: 90;
}
.main,
.main_mes_conversation {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  gap: 1em;
  padding: 1em 0.8em 5em 0.8em;
  height: 100%;
}
.main::-webkit-scrollbar {
  width: 6px; /* Largeur de la barre de défilement */
}

/* Styles pour le bouton de la barre de défilement (Firefox) */
.main::-webkit-scrollbar-thumb {
  background-color: #999; /* Couleur du bouton */
}

/* Styles pour la piste de la barre de défilement (Firefox) */
.main::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Couleur de la piste */
}

/* Styles pour la barre de défilement (Chrome, Safari, Edge) */
.main::-webkit-scrollbar-thumb {
  width: 6px; /* Largeur de la barre de défilement */
  background-color: #999; /* Couleur du bouton */
}

/* Styles pour la piste de la barre de défilement (Chrome, Safari, Edge) */
.main::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Couleur de la piste */
}
.response {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: auto;
  padding: 1em;
  border-radius: 10px 5px 10px 10px;
}
h6 {
  padding: 0.5em;
  background: white;
  border-radius: 10px;
  border: 2px solid green;
  color: rgb(90, 112, 90);
  cursor: pointer;
}
</style>
