import { createStore } from "vuex";
import instance from "../api";
import Swal from "sweetalert2";
import router from "@/router";
export default createStore({
  state: {
    isLanguage: JSON.parse(localStorage.getItem("language")) || "FR",
    Palier: "pilier-1",
    detailConversation: null,
    menuChatBot: [
      {
        module: "Forum",
        loading: "Chargement...",
        reponse:
          "Pour discuter avec d'autres membres,connectez-vous et cliquez sur l onglet 'Forum'",
      },
      {
        module: "Assistance",
        loading: "Chargement...",
        reponse: "Comment pourrions-nous vous être utiles ?",
      },
    ],
    menuChatBotEN: [
      {
        module: "Forum",
        loading: "Loading...",
        reponse:
          "To chat with other members, log in and click on the 'Forum' tab",
      },
      {
        module: "Assistance",
        loading: "Loading...",
        reponse:
          "One of our amazing advisors is getting ready to respond to you in a few minutes. Thank you for your patience and see you soon",
      },
    ],
    reponseChatBot: [],
    user: JSON.parse(localStorage.getItem("user")) || "",
    token: JSON.parse(localStorage.getItem("token")),
    spinner: false,
    isConnecte: false,
    Collecte: "pilier-1",
    articles: [],
    likes: [],
    Forum: [],
    arrayForFilterArticle: [],
    arrayForFilterForum: [],
    isloading: false,
    respondForumFil: [],
    responseArticleFil: [],
    reponseCommentaireArticle: [],
    reponseCommentaireForum: [],
    conversation: "",
    showADiscussion: false,
    reponsesForumReponse: [],
    nombreTotalCommentaireTypeArticle: 0,
    nombreTotalCommentaireForum: 0,
    id_chat: sessionStorage.getItem("ID_chat") || "",
    isItLarge: false,
  },
  getters: {},
  mutations: {
    // nouveau
    MODIFIER_NOMBRE_TOTAL_ARTICLES_COMMENTS(state, payload) {
      state.nombreTotalCommentaireTypeArticle = payload;
    },
    ADD_ELEMENT_RESPONSE_IN_COMMENT_ARTICLES(state, payload) {
      state.tableauReponseForOneArticle.forEach((item) => {
        if (item.id == payload.reponse_parent_id) {
          item.reponses.push(payload.data);
        }
      });
    },
    //ancien
    TOGGLE_DISCUSSION(state, payload) {
      state.showADiscussion = payload;
    },
    TOGGLE_ISITLARGE(state) {
      state.isItLarge = !state.isItLarge;
    },
    STOCK_ID(state, payload) {
      state.id_chat = payload;
    },
    TOOGLE_LANGUAGE(state, payload) {
      state.isLanguage = payload;
    },
    TOOGLE_PALLIER(state, payload) {
      state.Palier = payload;
    },
    TOOGLE_COLLECTE(state, payload) {
      state.Collecte = payload;
    },
    CHANGE_CHAT_BOT(state, payload) {
      state.reponseChatBot.push(payload);
    },
    VIDE_CHAT_BOT(state) {
      state.reponseChatBot = [];
    },
    CONNECT_USER(state, infoUser) {
      state.user = infoUser;
    },
    ADD_LIKE(state, payload) {
      state.likes.push(payload);
    },
    ADD_POST_FORUM(state, payload) {
      state.Forum.push(payload);
    },
    ADD_QUESTION_CONVERSATION(state, payload) {
      state.conversation = payload;
    },
    FILTER_ARTICLES(state, payload) {
      state.articles = state.arrayForFilterArticle.filter(
        (item) => item.theme.titre === payload
      );
    },
    FILTER_FORUM(state, payload) {
      state.Forum = state.arrayForFilterForum.filter((item) =>
        item.message.includes(payload)
      );
    },
    ADD_RESPONSE_FORUM(state, payload) {
      state.reponseCommentaireForum.unshift(payload);
    },
    ADD_RESPONSE_ARTICLE(state, payload) {
      state.reponseCommentaireArticle.unshift(payload);
    },
    ADD_REPONSE_IN_REPONSE_COMMENTAIRE(state, payload) {
      const element = payload.commentaires.find(
        (item) => item.id == payload.id
      );
      console.log(element);
    },
    GET_RESPONSE_COMMENTAIRE(state, payload) {
      const element = payload.allArticles.find((item) => item.id == payload.id);
      state.nombreTotalCommentaireTypeArticle = element.reponses.length;
      console.log(
        "state.nombreTotalCommentaireTypeArticle",
        state.nombreTotalCommentaireTypeArticle
      );
    },
    GET_RESPONSE_FORUM(state, payload) {
      console.log("payload", payload);
      state.nombreTotalCommentaireForum = payload;
    },
    ADD_ELEMENT_FORUM(state, payload) {
      state.detailConversation = payload;
    },
  },
  actions: {
    //nouveau

    //ancien
    async ConnectUser({ commit, state }, dataForConnect) {
      state.spinner = true;
      let dataUser = {
        email: dataForConnect.email,
        password: dataForConnect.password,
      };
      await instance
        .post("login", dataUser)
        .then((response) => {
          console.log("reponses", response.data);
          if (response.data.status === true) {
            state.isConnecte = true;
            commit("CONNECT_USER", response.data.data);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            localStorage.setItem(
              "token",
              JSON.stringify(response.data.access_token)
            );
            state.user = JSON.parse(localStorage.getItem("user"));
            state.token = JSON.parse(localStorage.getItem("token"));

            Swal.fire({
              icon: "success",
              title: state.isLanguage === "FR" ? "Connecté" : "Connected",
              showConfirmButton: false,
              timer: 1500,
            });
            state.spinner = false;
            router.push(dataForConnect.redirectPath);
          }
          if (response.data.status === false) {
            Swal.fire({
              icon: "error",
              title: response.data.message,
              showConfirmButton: true,
            });
            state.spinner = false;
            state.isConnecte = false;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: err.response.data.message,
            showConfirmButton: true,
          });
          state.spinner = false;
        });
    },
    DeconnecterUser({ state }) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      state.user = "";
      state.token = "";
      Swal.fire({
        icon: "success",
        title: state.isLanguage === "FR" ? "Déconnexion" : "Disconnect",
        showConfirmButton: false,
        timer: 1500,
      });
      // commit("LIST_FAVORIS", []);
      router.push("/");
      console.log(state.user);
    },
    async getListArticle({ state }) {
      state.isloading = true;
      try {
        const response = await instance.get("articles");
        state.arrayForFilterArticle = response.data.data;
        state.articles = response.data.data;
        console.log("state.likes", state.likes);
        state.isloading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async listForum({ state }) {
      try {
        const response = await instance.get("fils");

        console.log("listDataForum", response.data);
        state.Forum = response.data.data.slice().sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        state.arrayForFilterForum = response.data.data;
        // state.nombreTotalCommentaireForum = response.data.data.length;
      } catch (error) {
        console.log(error);
      }
    },
    // handleNbreCommentaire({state},payload){
    //   if (props.type === "Article") {
    //     store.dispatch("showResponseFilArticle", payload.id);
    //     nbreCommentaire.value = computed(
    //       () => store.state.nombreTotalCommentaire
    //     );
    //   }
    //   if (props.type === "Fil") {
    //     store.dispatch("showResponseFilForum", payload);
    //     nbreCommentaire.value = computed(
    //       () => store.state.nombreTotalCommentaireForum
    //     );
    //     console.log("nbreCommentaireFORUM", nbreCommentaire);
    //   }
    // };
    async showResponseFilForum({ state }, payload) {
      try {
        const response = await instance.get("reponseFils/" + payload);
        state.respondForumFil = response.data.data;
        state.reponseCommentaireForum = response.data.data.reponses;
        console.log("respondForumFil", response.data.data);
        console.log("reponseCommentaireForum", response.data.data.reponses);
      } catch (error) {
        console.log(error);
      }
    },
    async showResponseFilArticle({ state }, payload) {
      try {
        const response = await instance.get("reponseArticles/" + payload);
        state.responseArticleFil = response.data.data;
        state.reponseCommentaireArticle = response.data.data.reponses;

        console.log(
          "state.reponseCommentaireArticle",
          state.reponseCommentaireArticle
        );

        console.log("response.data.data", response.data.data);
        console.log("state.responseArticleFil", state.responseArticleFil);
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {},
});
