import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: HomeView },
  {
    path: "/actualite",
    name: "actualite",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Actualite/ActualiteParti.vue"
      ),
  },
  {
    path: "/grande-collecte",
    name: "grande-collecte",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/GrandeCollecte/CollecteViews.vue"
      ),
  },
  {
    path: "/don",
    name: "don",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Auth/DonationView.vue"),
  },
  {
    path: "/blog",
    name: "blog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Blog/BlogView.vue"),
  },

  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Auth/LoginView.vue"),
  },
  {
    path: "/forum",
    name: "forum",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Forum/ForumView.vue"),
  },
  {
    path: "/detail/forum/:id/:longueur",
    name: "forum_detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Forum/DetailForum.vue"),
  },
  {
    path: "/réinitialisation",
    name: "réinitialisation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/Password/ReinitialisationPaswword.vue"
      ),
  },
  {
    path: "/Mon_espace",
    name: "espace",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Espace/EspaceView.vue"),
  },
  {
    path: "/detail/:name/:id",
    name: "detail_event",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/Events/DetailEventView.vue"
      ),
  },
  {
    path: "/mediatheque",
    name: "mediatheque",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/Media/MediathequeView.vue"
      ),
  },

  {
    path: "/evenement",
    name: "Evenement",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Events/EventView.vue"),
  },

  {
    path: "/decisions",
    name: "decisions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Actualite/DecisionPresident.vue"
      ),
  },
  {
    path: "/chantier",
    name: "chantier",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Chantier/ChantierView.vue"
      ),
  },
  // {
  //   path: "/chantiers/:name",
  //   name: "chantier_name",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../views/Chantier/ChantierView.vue"
  //     ),
  // },
  {
    path: "/cabinet-president",
    name: "cabinet-president",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Gouvernance/CabinetVue.vue"
      ),
  },
  {
    path: "/vice-presidents",
    name: "vice-presidents",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Gouvernance/CollegeVicePresident.vue"
      ),
  },
  {
    path: "/detail_actualite/:id/:longueur",
    name: "detail_actualite",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Actualite/DetailActualite.vue"
      ),
  },

  {
    path: "/secretariat-executif",
    name: "secretariat-executif",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Gouvernance/SecretariatExecutif.vue"
      ),
  },
  {
    path: "/districts",
    name: "districts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Gouvernance/HautRepresentant.vue"
      ),
  },
  {
    path: "/mot-president",
    name: "mot-president",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Parti/MotDuPresident.vue"
      ),
  },
  {
    path: "/histoire",
    name: "histoire",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Parti/HistoirePdci.vue"),
  },
  {
    path: "/presidents",
    name: "presidents",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Parti/PresidentElus.vue"
      ),
  },
  {
    path: "/statuts-reglements",
    name: "statuts-reglements",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Parti/StatusReglement.vue"
      ),
  },
  {
    path: "/inscription",
    name: "inscription",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Adherer/AdhererView.vue"
      ),
  },
  // {
  //   path: "/conversation",
  //   name: "conversation",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../components/conversations/ConversationsView.vue"
  //     ),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (
      store.state.Palier === "pilier-2" ||
      store.state.Palier === "pilier-3" ||
      store.state.Palier === "pilier-4" ||
      store.state.Palier === "pilier-5" ||
      store.state.Palier === "pilier-6" ||
      store.state.Palier === "pilier-7"
    ) {
      return { top: 200 };
    } else {
      return { top: 0 };
    }
  },
});

export default router;
